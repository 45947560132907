<template>
  <div class="flex items-center justify-end gap-x-4">
    <AnchorButton
      :variant="`secondary`"
      :disabled="!hasPrev"
      :text="'Prev'"
      @click="onPrev"
    />

    <AnchorButton
      :variant="'primary'"
      :text="submitButtonText"
      @click="onSubmit"
    />

    <AnchorButton
      :variant="`secondary`"
      :disabled="!hasNext"
      :text="'Next'"
      @click="onNext"
    />
  </div>
</template>

<script>
// import { XStepperManager, xStepperEmitter } from '@/plugins/xStepper'
import { xStepperEmitter } from '@/plugins/xStepper'

import AnchorButton from '../form/AnchorButton.vue'
export default {
  components: { AnchorButton },
  name: 'XStepperNavigation',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    submitButtonText: {
      required: false,
      default: 'Save',
    },
  },
  data() {
    return {
      // todo: based on the mode & step state (isFree) -> next & prev btn should be disabled
      // generally, in strict mode, next button will always be disabled -> only after submission
      // it'll be unlocked but it should'nt be hard coded e.g. always disable next button in strict mode
      // rather check for the next step state (mode === 'strict' nextStep.isFree === true => )
      currentStep: null,
    }
  },
  mounted() {
    xStepperEmitter.on(
      this.$xStepper.getEventName('changeStep', this.id),
      (e) => {
        this.$emit('input', e)
        this.currentStep = e
      }
    )
  },
  computed: {
    hasPrev() {
      return this.value > 1
    },
    hasNext() {
      return this.value < this.steps.length
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(updatedVal) {
        this.currentStep = updatedVal
      },
    },
  },
  methods: {
    onPrev() {
      // todo: prevent on strict mode?
      this.hasPrev && this.$emit('input', this.value - 1)
      // this.$xStepper.navigate(this.id).prev()
    },
    onNext() {
      this.hasNext && this.$emit('input', this.value + 1)
      // this.$xStepper.navigate(this.id).next()
    },
    /**
     * Emit submit event with current step details
     */
    onSubmit() {
      this.$emit('submit', {
        step: this.value,
        details: this.steps[this.value - 1],
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
