<template>
  <div>
    <!-- debug: {mode: {{ mode }}, current-step: {{ value }}} -->

    <div :class="`flex-wrap x-stepper-root`">
      <!-- {{ steps || 'nothig' }} -->
      <div
        v-for="(step, stepIndex) in theSteps"
        :key="`step-item-${stepIndex}`"
        :class="['step', mode, stepIndex + 1 === value ? 'active' : 'inactive']"
        @click="onClickStep(stepIndex + 1)"
      >
        <section class="label">
          <span
            :class="
              `index ${mode} ${step.free ? 'free' : 'kept'} ${
                stepIndex + 1 === value ? 'active' : 'inactive'
              }`
            "
          >
            <span class="icon">✓</span>
            <span class="text">{{ stepIndex + 1 }}</span>
          </span>

          <span class="title"> {{ step.title }} </span>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// https://www.webpackbin.com/bins/-KvHS7KEmrTLJOYWS9k2
// https://material.angular.io/components/stepper/overview
// https://cristijora.github.io/vue-form-wizard/#/?id=demos
// https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript

// Components
import { XStepperManager, xStepperEmitter } from '@/plugins/xStepper'

// Implementation
import { computed, defineComponent, ref } from '@vue/composition-api'
export default defineComponent({
  name: 'XStepper',
  components: {
    // XStepperItem: () => import('./XStepperItem'),
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    /*
     * free: can move to any step, all steps are passed
     * swing: can move to any step, passing state is unknown
     * strict: can not move unless the previous step is passed
     */
    mode: {
      type: String,
      required: false,
      default: 'free',
      validator(val) {
        return ['strict', 'swing', 'free'].includes(val)
      },
    },
    value: {
      type: Number,
      required: true,
      default: 1,
    },
    debug: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const xStepperManager = new XStepperManager()

    const theSteps = ref(props.steps)

    const stepTotal = computed(() => {
      return props.steps.length
    })

    const stepCurrent = ref(props.value)

    // onMounted(
    //   xStepperEmitter.emit(`xs-current-step:${props.id}`, stepCurrent.value)
    // )

    // Get nav state
    function hasPrev() {
      return stepCurrent.value > 1
    }
    function hasNext() {
      return stepCurrent.value < stepTotal.value
    }
    function isNavigable(toStep) {
      return Number.isInteger(toStep) && toStep > 1 && toStep <= stepTotal.value
    }

    // only if step.free === true, user can move, in 'strict' mode
    // in 'free' mode regardless of the step.free === false, user is free to move
    function onClickStep(e) {
      const stepOrder = e
      const toIndex = stepOrder - 1

      if (props.mode === 'strict') {
        if (theSteps.value[toIndex].free === true) {
          stepCurrent.value = stepOrder
          emit('input', stepOrder)

          props.debug && console.log({ onClickStep: stepCurrent.value })
          return
        }
        props.debug && console.log({ onClickStep: false })
        return
      }

      emit('input', stepOrder)
      stepCurrent.value = stepOrder
      props.debug && console.log({ onClickStep: stepCurrent.value })

      xStepperEmitter.emit(
        xStepperManager.getEventName('changeStep', props.id),
        stepOrder
      )
    }

    function registerEvents() {
      xStepperEmitter.on(
        xStepperManager.getEventName('defineSteps', props.id),
        (e) => {
          theSteps.value = e.steps
          props.debug && console.warn('defineReceivedSet', theSteps.value)
        }
      )

      xStepperEmitter.on(
        xStepperManager.getEventName('nextStep', props.id),
        () => {
          if (hasNext()) {
            onClickStep(stepCurrent.value + 1)
          }
        }
      )

      xStepperEmitter.on(
        xStepperManager.getEventName('prevStep', props.id),
        () => {
          if (hasPrev()) {
            onClickStep(stepCurrent.value - 1)
          }
        }
      )

      xStepperEmitter.on(
        xStepperManager.getEventName('jumpStep', props.id),
        (e) => {
          // event data structure is defined in xStepper.js::to()
          if (isNavigable(e.navigateTo)) {
            onClickStep(e.navigateTo)
          }
        }
      )

      props.debug && console.log({ XStepperEvents: xStepperEmitter.all })
    }

    registerEvents()

    return {
      theSteps,
      stepTotal,
      stepCurrent,
      onClickStep,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './sass/variables';

.x-stepper-root {
  display: flex;
  width: inherit;
  user-select: none;
  box-sizing: border-box;
  justify-content: space-between;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  & .step {
    flex: 1;
    opacity: 0.2;
    box-sizing: border-box;
    transition: opacity 0.4s;
    color: $app-color-white;
    padding: 6px 8px;
    // margin-right: 1rem;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 120px;
    border-radius: 99rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover:not(.is-disabled) {
      opacity: 0.85;
    }

    // todo: free % strict
    &.free {
      &.inactive {
        border: 1px solid green;
        opacity: 0.9;
      }
      &.active {
        border: 1px solid #333;
        opacity: 1;
      }
    }

    &.strict {
      &.inactive {
        border: 1px solid $app-color-dusty-gray;
      }
      &.active {
        border: 1px solid #333;
        opacity: 1;
      }
    }

    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .index {
      display: flex;
      flex-shrink: 0;
      place-content: center;
      align-items: center;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 14px;
      border-radius: 50%;
      //font-size: 0.65rem;
      color: #ffffff;
      padding: 0px;
      margin: 0px;
      content: '';

      /*
       * icon: onInactive
       * text: onActive
      **/
      &.free {
        &.active {
          background-color: #000000;
          & .icon {
            display: none;
          }
          & .text {
            display: inline;
          }
        }
        &.inactive {
          background-color: green;
          & .icon {
            display: inline;
          }
          & .text {
            display: none;
          }
        }
      }

      /*
       * icon:
       *  - black: onInactive
       *  - green: onInactive && onFree
       * text:
       *  - black: onActive
       *  - green: onActive && onFree
      **/
      &.strict.kept {
        &.active {
          background-color: #000000;
          & .icon {
            display: none;
          }
          & .text {
            display: inline;
          }
        }
        &.inactive {
          background-color: #000000;
          // border-color: #000000;
          & .icon {
            display: none;
          }
          & .text {
            display: inline;
          }
        }
      }

      &.strict.free {
        &.active {
          background-color: #000000;
          & .icon {
            display: none;
          }
          & .text {
            display: inline;
          }
        }
        &.inactive {
          background-color: green;
          & .icon {
            display: inline;
          }
          & .text {
            display: none;
          }
        }
      }
    }

    .title {
      margin-left: 7px;
      font-size: 14px;
      font-weight: 500;
      color: $app-color-mirage;
    }

    // todo: change is-visited state after adding is-success state
    &.is-visited {
      opacity: 1;
      border: 1px solid $app-color-visited-green;
      .label {
        .title {
          color: $app-color-visited-green;
          //font-family: Roboto;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #00bb3d;
        }
        .index {
          color: $app-color-white;
          background-color: $app-color-visited-green;
        }
      }
    }
    &.is-disabled {
      opacity: 0.3;
      .title {
        //font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $app-color-black;
      }
    }

    &.is-active {
      opacity: 1;
      border: 1px solid $app-color-mirage;

      .label {
        .title {
          //font-family: Roboto;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }
        .index {
          color: $app-color-white;
          background-color: $app-color-black;
        }
      }
    }

    // todo: add success state
    &.is-success {
      opacity: 1;
      border: 1px solid $app-color-visited-green;
      .label {
        .title {
          color: $app-color-visited-green;
          //font-family: Roboto;
          font-size: 15px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #00bb3d;
        }
        .index {
          color: $app-color-white;
          background-color: $app-color-visited-green;
        }
      }
    }
  }
}
</style>
